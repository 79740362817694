import {html, nothing} from "lit";
import {QuestionnaireDomain} from "../domain/questionnaire-domain";
import {AuthenticatedMixin, OnboardedMixin, PWAPage} from "./pwa-page";
import {highlightAndShowTooltip} from "./tour";
import {Task} from "@qogni-technologies/design-system/src/shared/task";
import {isValidUuid4} from "@qogni-technologies/design-system/src/shared/common";
import {until} from "lit/directives/until.js";
import {keyed} from "lit/directives/keyed.js";
import {createRef, ref} from "lit/directives/ref.js";

/**
 * Class representing a questionnaire page.
 *
 * Questionnaire Flow:
 * 1. Introduction Text: renderIntro() only called when hasIntro is true.
 * 2. Tour Intro: renderTourIntro() only called when hasIntro is true.
 * 3. Tour: renderTour() only called when hasIntro is true.
 * 4. Questionnaire flow, managed internally.
 *
 * @extends PWAPage
 * @mixes OnboardedMixin
 * @mixes AuthenticatedMixin
 * @author Tom Valk
 */
export class QuestionnairePage extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #categoryPromise;
  initPromise;

  #domain;

  #categoryId;
  #questionnaire;
  #queue = [];
  #queuePromise = Promise.resolve();
  #timer = null;
  #undoDetails = null;
  #numAnswered = 0;
  #componentRef = createRef();

  canStartNew = true;
  hasFinishedBefore = false;

  intervalTime = 10000;
  #componentDemoShown = false;

  constructor() {
    super();
    this.questions = null;
    this.#domain = new QuestionnaireDomain();
    this.title = 'Keep going!';
    this.subTitle = 'Keep answering questions to get the App more personalized.';
    this.loading = true;
    this.step = 0;
    this.currentQuestion = 0;
    this.renderKey = this.randomString(32);
    this.#queue = [];
  }

  static properties = {
    currentQuestion: {type: Number, attribute: false},
    subTitle: {type: String, attribute: false},
    title: {type: String, attribute: false},
    loading: {type: Boolean, attribute: false},
    step: {type: Number, attribute: false},
    renderKey: {type: String, attribute: false},
  }

  get queue() {
    return this.#queue;
  }

  get questionnaire() {
    return this.#questionnaire
  };

  get undoDetails() {
    return this.#undoDetails;
  }

  get hasIntro() {
    return false;
  }

  get hasRewards() {
    return false;
  }

  get rewards() {
    return {};
  }

  get categoryId() {
    return this.#categoryId;
  }

  get categoryName() {
    return null;
  }

  set categoryId(id) {
    this.#categoryId = id;
  }

  get tourSteps() {
    return [
      {
        selector: '#tour_progress',
        content: 'Over here you can see your progress. The more filled the closer you are to finishing.',
        timeout: 5000
      },
      {
        selector: '#tour_cards',
        content: 'This is the question. you can either answer by swiping the card left or right, or by pressing the True or False button.',
        timeout: 10000
      },
    ];
  }

  connectedCallback() {
    super.connectedCallback();
    if (!this.categoryId && !this.categoryName) {
      app.addToastMessage('We don\'t know which questionnaire to run. Please navigate to home and try again');
      return;
    }

    this.#categoryPromise = new Promise((resolve) => {
      if (this.categoryId) return resolve(null);
      this.#domain.getQuestionnaireCategoryIdByName(this.categoryName).then((id) => {
        this.categoryId = id;
        return resolve(id);
      });
    });

    // Load last queue.
    if (localStorage.getItem('questionnaire_queue_' + this.categoryName.toLowerCase())) {
      this.#queue = JSON.parse(localStorage.getItem('questionnaire_queue_' + this.categoryName.toLowerCase()));
      // console.log('Loaded previous queue', this.#queue);
      if (!this.#queue.length || !Array.isArray(this.#queue)) {
        localStorage.removeItem('questionnaire_queue_' + this.categoryName.toLowerCase());
        this.#queue = [];
      }
    }

    // Init.
    this.initPromise = this.init();

    // Set timer for sending queue.
    this.#timer = setTimeout(this.startQueue.bind(this), this.intervalTime);
    setInterval(() => {
      if (!this.#timer) this.#timer = setTimeout(this.startQueue.bind(this), this.intervalTime);
    }, 50000); // Fallback, in case of any error.

    // Send queue when minimizing tab/app.
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "hidden" && document.hasFocus() && this.#queue.length > 0) {
        // const localQueue = this.#queue;
        // this.#queue = [];
        // localStorage.setItem('questionnaire_queue_' + this.categoryName.toLowerCase(), JSON.stringify(this.#queue));

        // this.#domain.sendQueue(localQueue).then((response) => {
        //   if (response && response.data) this.#questionnaire = response.data;
        //   this.#queue = [];
        //   localStorage.removeItem('questionnaire_queue_' + this.categoryName.toLowerCase());
        // }).catch(() => {
        //   this.#queue = [...localQueue, ...this.#queue];
        //
        // });
      }
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    if (this.#timer) clearInterval(this.#timer);
  }

  async #startQuestionnaire(e) {
    if (e)
      e.preventDefault();

    // Create new questionnaire.
    this.#questionnaire = await this.#domain.createQuestionnaire(this.categoryId);

    // Request re-rendering.
    this.requestUpdate();
  }

  async #continueQuestionnaire(e) {
    e.preventDefault();

    this.step = 3;

    // Request re-rendering.
    this.requestUpdate();
  }

  async skipIntro() {
    const task = async () => {
      if (!this.#questionnaire)
        await this.#startQuestionnaire();
      await this.init();
      this.step = 3;
    }
    await Task.run(task, {
      ghost: document.documentElement,
      description: 'Initiating the questionnaire...'
    });
  }

  async nextStep(e) {
    e.preventDefault();
    if (this.hasIntro) {
      this.step++;
    } else {
      this.step = 3;
    }
    if (this.step === 3) {
      this.loading = true;
      await this.#startQuestionnaire();
      await this.init();
      this.loading = false;
    }
    this.requestUpdate();
  }

  async startButtonClick(e) {
    if (this.#questionnaire) return this.#continueQuestionnaire(e);
    return this.nextStep(e);
  }

  renderIntro() {
    return html`renderIntro() is not overridden`;
  }

  renderTourIntro() {
    return html`renderTourIntro() is not overridden`;
  }

  renderTour() {
    setTimeout(async () => {
      if (this.step === 2) {
        for (const step of this.tourSteps) {
          if (!this.#questionnaire)
            await highlightAndShowTooltip(step.selector, step.content, step.timeout);
        }
        if (!this.#questionnaire)
          await this.skipIntro();
      }
    }, 50);
    return html`
      <tour-container>
        <button type="button" class="outline tour-skip" @click=${this.skipIntro}>
          Skip
        </button>
      </tour-container>

      <progress-indicator type="line" id="tour_progress"
                          style="margin-top: var(--gutter-small);"
                          theme="green"
                          value=30
                          max=70>
      </progress-indicator>
      <swipe-cards
        id="tour_cards"
        .items=${this.demoQuestions}
        disabled
      ></swipe-cards>
    `;

  }

  render() {
    if (this.hasIntro) {
      switch (this.step) {
        case 0:
          return this.renderIntro();
        case 1:
          return this.renderTourIntro();
        case 2:
          return this.renderTour();
      }
    } else if (this.step === 0) return this.renderIntro();

    return this.renderQuestionnaire();
  }

  pushQueue(options) {
    this.#queue.push(options);
    localStorage.setItem('questionnaire_queue_' + this.categoryName.toLowerCase(), JSON.stringify(this.#queue));
  }

  async startQueue() {
    if (this.#queuePromise) await this.#queuePromise;
    this.#queuePromise = this.processQueue();
    await this.#queuePromise;
    this.#timer = setTimeout(this.startQueue.bind(this), this.intervalTime);
    return this.#queuePromise;
  }
  randomString(length) {
    return [...Array(length)].map(() => Math.random().toString(36)[2]).join('');
  }

  /**
   * Processes the queue of items by sending them to the domain server and updating the questionnaire.
   *
   * If the queue is empty, the method returns early.
   * Otherwise, the method:
   * - Stores a copy of the current queue in a local variable.
   * - Resets the queue to an empty array.
   * - Sends the local queue to the domain server.
   * - Updates the questionnaire if a response with data is received.
   * - Removes the questionnaire queue from localStorage.
   * - If an error occurs:
   *   - Checks if the error has a response status of 400.
   *   - If true, resets the queue, removes the questionnaire queue from localStorage, reloads the questions, updates the current question, and requests an update.
   *   - If false, appends the local queue back to the queue, stores the queue in localStorage, and logs an error message.
   *
   * @return {Promise<void>}
   */
  async processQueue() {
    if (this.#queue.length === 0) {
      return;
    }

    const localQueue = this.#queue;
    this.#queue = [];

    try {
      const response = await this.#domain.sendQueue(localQueue);
      if (response && response.data) this.#questionnaire = response.data;
      localStorage.removeItem('questionnaire_queue_' + this.categoryName.toLowerCase());
    } catch (e) {
      // Fallback, restore current + local queue, and set in localStorage.
      if (e?.response?.status === 400) {
        console.error('Queue contains invalid data. Resetting queue!', e?.errorData?.message);
        this.#queue = [];
        localStorage.setItem('questionnaire_queue_' + this.categoryName.toLowerCase(), JSON.stringify(this.#queue));

        // Reload questions and update.
        await this.init(true);
        this.currentQuestion = this.questionnaire.current_step;
        this.renderKey = this.randomString(32); // forces rerendering of the element.
        this.requestUpdate();
      } else {
        console.error('Processing queue failed! Trying again later.');
        this.#queue = [...localQueue, ...this.#queue];
        localStorage.setItem('questionnaire_queue_' + this.categoryName.toLowerCase(), JSON.stringify(this.#queue));
      }
    }

    this.#queuePromise = Promise.resolve();
  }

  async init(force = false) {
    // Get current category from the server if we only have the name.
    await this.#categoryPromise;
    if (!this.categoryId && !this.categoryName) {
      app.addToastMessage('We don\'t know which questionnaire to run. No category set!');
      return nothing;
    }

    if (!this.categoryId) {
      app.addToastMessage('We don\'t know which questionnaire to run. Category not found!');
      return nothing;
    }

    // Retrieve ongoing questionnaires of the user with the specific category. Otherwise, show start-new rendering.
    if (!this.#questionnaire || force) {
      if (this.hasIntro && this.step === 3 && !force) {
        await this.#startQuestionnaire();
      } else {
        const questionnaires = await this.#domain.retrieveMyQuestionnaires(this.categoryId);
        if (!questionnaires || questionnaires.length === 0) return;
        this.#questionnaire = this.#domain.filterRunningQuestionnaire(questionnaires);
        this.canStartNew = this.#domain.canStartNewQuestionnaire(questionnaires);
        this.hasFinishedBefore = this.#domain.filterFinishedQuestionnaire(questionnaires);
        if (!this.#questionnaire) return;
        this.currentQuestion = this.#questionnaire.current_step;
      }
    }

    // Retrieve questions.
    if (!this.questions || force) {
      const questions = this.#domain.enrichQuestions(
        this.#questionnaire, await this.#domain.retrieveQuestions(this.#questionnaire.id, app.session?.user?.language)
      );
      this.numQuestions = questions.length;
      this.questions = questions;
    }

    // Process queue when not empty, but don't await it.
    if (this.#queue.length > 0) {
      try {
        await this.processQueue();
        await this.init(true);
        setTimeout(() => {
          this.requestUpdate()
        }, 25);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async showResults(e) {
    e.preventDefault();
    window.location.replace(`/results?finished=1&categoryName=${this.categoryName}`);
  }

  async undo() {
    if (!this.#undoDetails) return;
    if (this.currentQuestion > 0) {
      this.currentQuestion--;
      this.#numAnswered = 0;
    }

    this.pushQueue({
      action: 'undo',
      ...this.#undoDetails
    });
  }

  async answer(e) {
    let reaction = e.detail.reaction;
    if (!isValidUuid4(reaction)) reaction = e.detail.reaction === 'like' ? 'Yes' : 'No';

    this.#undoDetails = {
      value: reaction,
      question_id: e.detail.card.question_id,
      questionnaire_question_id: e.detail.card.id,
      questionnaire_id: this.#questionnaire.id,
    };

    this.currentQuestion++;
    this.#numAnswered++;

    this.pushQueue({
      action: 'answer',
      value: reaction,
      question_id: e.detail.card.question_id,
      questionnaire_question_id: e.detail.card.id,
      questionnaire_id: this.#questionnaire.id,
    });
  }

  leaveCelebration() {
    this.#numAnswered = 0;
    this.requestUpdate();
  }

  renderQuestionnaire() {
    if (!this.questions && !this.loading) {
      return html`
        <section class="card">
          <p>Your ${this.categoryName} can't be started right now.</p>
          <code>Questionnaire not found</code>
        </section>`;
    } else if (!this.questions && this.loading) {
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
      `;
    }
    if (this.currentQuestion === this.questions.length) {
      return this.renderFinished();
    }

    return this.renderQuestionnaireContent();
  }

  renderFinished() {
    const task = Task.run(async () => {
      await this.#queuePromise;
      await this.processQueue();
    });
    return html`
      <reward-animation></reward-animation>

      <section class="card">
        <figure class="center mb-small">
          <img src="/assets/img/balance.svg" alt="Girl balancing" loading="lazy"/>
        </figure>

        <div class="center">
          <h2>Amazing, you finished the ${this.categoryName}</h2>
          <p>
            We just received your last answer, this is amazing news, because your results are in!
            Head over to the results page to view your personalized profile and recommendations.
          </p>

          <button @click=${this.showResults} class="next finished"
                  ?disabled="${until(task.then(() => false), true)}">
            See your results
          </button>
        </div>
      </section>
    `;
  }

  renderCelebration() {
    if (
      this.hasRewards &&
      Object.prototype.hasOwnProperty.call(
        this.rewards,
        this.currentQuestion
      ) &&
      this.#numAnswered !== 0
    ) {

      return html`
        <reward-animation></reward-animation>

        <master-detail>
          <section class="card">
            <figure class="center mb-small">
              <img
                src="/assets/img/balance.svg"
                alt="Girl balancing"
                loading="lazy"
              />
            </figure>

            <div class="center">
              <h2>${this.rewards[this.currentQuestion].title}</h2>
              <p>${this.rewards[this.currentQuestion].text}</p>

              <button
                @click=${this.leaveCelebration}
                class="next reward"
              >
                Continue the ${this.categoryName}
              </button>
            </div>
          </section>
        </master-detail>

      `;
    }
    return nothing;
  }

  renderQuestionnaireContent() {
    return html`
      ${this.renderCelebration()}
      <section class="">
        <h1>${this.title}</h1>
        <p>
          ${this.subTitle}
        </p>
      </section>

      <progress-indicator type="line"
                          theme="green"
                          label=""
                          .value=${this.currentQuestion}
                          .max=${this.numQuestions}>
      </progress-indicator>

      ${this.renderCardComponent()}
    `;
  }

  updated(props) {
    super.updated(props);

    if (this.#componentRef.value && ! this.#componentDemoShown && typeof this.#componentRef.value?.firstStart === 'function') {
      this.#componentDemoShown = true;
      setTimeout(() => {
        this.#componentRef.value.firstStart();
      }, 250);
    }
  }

  renderCardComponent() {
    const answeredQuestions = this.questions.filter(question => question.answered === true);
    const numberOfAnsweredQuestions = answeredQuestions.length;

    return html`
      ${keyed(this.renderKey, html`
        <swipe-cards
          ${ref(this.#componentRef)}
          @load=${() => alert}
          ?debug="${["localhost", "127.0.0.1"].includes(location.hostname)}"
          offset=${numberOfAnsweredQuestions}
          .items=${this.questions}
          @answer=${this.answer}
          @undo=${this.undo}
        ></swipe-cards>
      `)}
    `;
  }
}

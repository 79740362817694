import { Task } from "@qogni-technologies/design-system/src/shared/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { QuestionnairePage } from "../shared/questionnaire";
import {keyed} from "lit/directives/keyed.js";

export class PageHealthcheck extends QuestionnairePage {
  #startButtonRef = createRef();

  constructor() {
    super();
    this.answers = [];
  }

  get categoryName() {
    return "HealthCheck";
  }

  get hasRewards() {
    return true;
  }

  get rewards() {
    return {
      20: {
        title: "You have completed 20 questions!",
        text: "Keep it up. You’ve earned 20 points already!",
        image: "/assets/img/balance.svg",
        rewardAmount: 20,
      },
      60: {
        title: "You have completed 60 questions!",
        text: "Keep it up. You’ve earned 60 points so far.",
        image: "/assets/img/balance.svg",
        rewardAmount: 60,
      },
      100: {
        title: "You have completed 100 questions! Almost there",
        text: "You are in the final stretch. You Rock. Did you know that out of the 92% that completed the HealthCheck, 42% indicated that they are inspired to make a change",
        image: "/assets/img/balance.svg",
        rewardAmount: 100,
      },
    };
  }

  async connectedCallback() {
    await super.connectedCallback();
    Task.run(
      async () => {
        await this.initPromise;
        this.loading = false;
      },
      {
        ghost: this.#startButtonRef.value,
      }
    );
  }

  renderIntro() {
    return html`
      <section class="hero center hide-full-width">
        <h1>HealthCheck</h1>
      </section>

      <section class="card">
        <accordion-list>
          <details class="red" open>
            <summary>What is the HealthCheck?</summary>
            The HealthCheck evaluates 24 key body systems, including liver
            function, gut health, and hormonal balance. By identifying
            high-priority areas, Qogni provides hyper-personalized content to
            offer actionable insights and improve your health.
          </details>
          <details class="yellow">
            <summary>How long does it take?</summary>
            The HealthCheck takes about 15 minutes to complete. You can pause
            and resume anytime; your progress will be saved.
          </details>
          <details class="green">
            <summary>Can I skip questions?</summary>
            To ensure the accuracy of your assessment, it’s important to answer
            all questions. <u>Individual responses are not saved</u>, preserving
            your privacy.
          </details>
          <details class="blue">
            <summary>How important is the test?</summary>
            The HealthCheck is essential for creating your personalized content
            on Qogni. It helps you understand critical health areas, such as
            cardiovascular function, immune system performance, and blood sugar
            regulation, forming the foundation for enhancing your well-being.
          </details>
        </accordion-list>

        ${this.questionnaire
          ? html` <p>Resume your unfinished ${this.categoryName}.</p> `
          : nothing}
        ${this.canStartNew !== false
          ? html`
              <button
                type="button"
                class="wide"
                ?disabled=${this.loading}
                ${ref(this.#startButtonRef)}
                @click=${this.startButtonClick}
              >
                ${this.questionnaire
                  ? html` Continue the ${this.categoryName} `
                  : html` Start the ${this.categoryName} `}
              </button>
            `
          : html`
              <p>You recently finished a ${this.categoryName}.</p>
              <a href="/results" class="button wide small">
                Show results of last ${this.categoryName}
              </a>
            `}
        ${this.hasFinishedBefore && this.canStartNew !== false
          ? html`
              <section class="card mt-tiny">
                <p>
                  You previously finished another ${this.categoryName}. You can
                  see your results below.
                </p>
                <a href="/results" class="button outline wide small">
                  Show results of last ${this.categoryName}
                </a>
              </section>
            `
          : nothing}
      </section>
    `;
  }

  renderCardComponent() {
    const answeredQuestions = this.questions.filter(question => question.answered === true);
    const numberOfAnsweredQuestions = answeredQuestions.length;
    return html`
      ${keyed(this.renderKey, html`
        <multi-answers
          offset=${numberOfAnsweredQuestions}
          .questions=${this.questions}
          @answer=${this.answer}
          @undo=${this.undo}
          quick-answer
          can-undo
          ?undo-disabled=${!this.undoDetails}>
        </multi-answers>
      `)}
    `;
  }

  renderWidget() {
    this.setAttribute("hidden", "");
    return nothing;
  }
}

import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../shared/pwa-page";
import { QuestionnaireDomain } from "../domain/questionnaire-domain";
import { Task } from "@qogni-technologies/design-system/src/shared/task";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { repeat } from "lit/directives/repeat.js";
import { Converter } from "showdown";
import { getNeurotransmitterColor } from "../shared/common";

export class PageResults extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;
  #sampleGraph = [{
    color: "#e54271",
    label: "Dopamine",
    value: 0.43333333333333335,
    oldValue: 0.80,
  }, {
    color: "#e7aa5e",
    label: "Acetylcholine",
    value: 0.5743243243243243,
    oldValue: 0.70,
  }, {
    color: "#519bc5",
    label: "GABA",
    value: 0.4722222222222222,
    oldValue: 0.50,
  }, {
    color: "#49946a",
    label: "Serotonin",
    value: 0.5166666666666666,
    oldValue: 0.50,
  }];

  constructor() {
    super();
    this.#domain = new QuestionnaireDomain();
    this.loading = true;
    this.results = {};
  }

  static properties = {
    results: { type: Object, attribute: false },
    loading: { type: Boolean, attribute: false },
  };

  connectedCallback() {
    super.connectedCallback();
    Task.run(async () => {
      const response = await this.#domain.getLatest();
      this.results = response.data;
      this.loading = false;
    });
    this.hasActiveProgram = app.session.user?.active_program !== null;
  }

  sortNeurotransmitters(array) {
    // Define the desired order
    const sortOrder = ["Dopamine", "Acetylcholine", "GABA", "Serotonin"];
    const orderByProperty = "label";

    return array.sort((a, b) => {
        const indexA = sortOrder.indexOf(a[orderByProperty]);
        const indexB = sortOrder.indexOf(b[orderByProperty]);

        // Handle undefined values (if a property value is not in the sortOrder array)
        if (indexA === -1 && indexB !== -1) return 1;
        if (indexA !== -1 && indexB === -1) return -1;
        if (indexA === -1 && indexB === -1) return 0;

        return indexA - indexB;
    });
}

  renderVectorStat(score, type) {
    if (!score)
      return html`
        <section class="card preview">
          <hgroup>
            <h2>
              ${type === "cognitive-performance"
                ? html` Cognition and stress resilience `
                : html` Relax & Recover ability `}
            </h2>
          </hgroup>

          ${type === "cognitive-performance"
            ? html`
                <vector-stat
                  score="71"
                  label="Sufficient"
                  vector="brain"
                ></vector-stat>
              `
            : html`
                <vector-stat
                  score="79"
                  label="Sufficient"
                  vector="person"
                ></vector-stat>
              `}

          <p>
            ${type === "cognitive-performance"
              ? html`
                  Steady as you go. Consider aiming for even greater cognitive
                  heights.
                `
              : html`
                  You're at a crucial point for relaxation. Let's initiate
                  recovery strategies.
                `}
          </p>

          <details class="simple">
            <summary>
              <svg-icon icon="info"></svg-icon>
              More information
            </summary>
            <!-- Content -->
            <section>
              <p>
                ${type === "cognitive-performance"
                  ? html`
                      Though you're holding on, there's much untapped potential.
                      Strengthening your relaxation foundation is essential.
                      Regular self-care, coupled with consistent downtime, can
                      do wonders.
                    `
                  : html`
                      Impressive! You excel in recovering from stress and
                      tension. Your resilience ensures that you're always at
                      your best, both mentally and physically. Continue to
                      nurture this strength, and inspire others with your
                      recuperative skills.
                    `}
              </p>
            </section>
          </details>
        </section>
      `;

    return html`
      <section class="card">
        <hgroup>
          <h2>
            ${type === "cognitive-performance"
              ? html` Cognition and stress resilience `
              : html` Relax & Recover ability `}
          </h2>
        </hgroup>

        <vector-stat
          score="${score.output ? score.output * 100 : this.#domain.getScorePercentage(score.score)}"
          label="${score.score}"
          vector="${type === "cognitive-performance" ? "brain" : "person"}"
        ></vector-stat>

        <translatable-content current-language="en">
          <p>${score.questionnaire_score_text}</p>
        </translatable-content>


        <details class="simple">
          <summary>
            <svg-icon icon="info"></svg-icon>
            More information
          </summary>
          <!-- Content -->
          <section>
            <translatable-content current-language="en">
              <p>${score.questionnaire_score_description}</p>
            </translatable-content>
          </section>
        </details>
      </section>
    `;
  }

  render() {
    if (this.loading)
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="image mb"></app-shimmer>

        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>

        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;

    const healthcheckResults = this.results?.healthcheck;
    const braincheckResults = this.results?.braincheck;

    return html`
      ${this.renderBrainCheck(braincheckResults)}
      ${this.renderHealthCheck(healthcheckResults)}
    `;
  }

  renderBrainCheck(bc) {
    return html`
      <div class="${!bc ? "preview-wrap" : ""}">
        ${!bc ? html`
          <div class="preview-content">
            <badge-tag class="mb-tiny blue small">
              BrainCheck Results
            </badge-tag>

            <h1>BrainCheck not finished!</h1>
            <p>
              You haven't completed a BrainCheck before. In order to see
              your personalized brain performance, head over to the
              BrainCheck and complete it!
            </p>

            <flex-container class="justify-content-center">
              <flex-item>
                <a href="/braincheck" class="button green wide">
                  Go to the BrainCheck
                </a>
              </flex-item>
            </flex-container>
          </div>` : nothing}

        <section class="hero center">
          <h1>Great job!</h1>
        </section>

        ${this.renderBrainCheckStats(bc)}
      </div>
    `;
  }

  renderBrainCheckStats(bc) {
    if (! bc) return this.renderBrainCheckDefaults();

    const coqnitiveScore =
      bc?.questionnaire_stats
        ?.filter((n) => n.score_type === "cognitive-performance")
        ?.at(0) ?? null;
    const relaxRecoverScore =
      bc?.questionnaire_stats
        ?.filter((n) => n.score_type === "relax-recover")
        ?.at(0) ?? null;
    const availableProgram = bc?.available_program;
    const fivePillars = [];

    if (this.results?.five_pillars_v2 && Object.keys(this.results?.five_pillars_v2)?.length > 0) {
      for (const pillarKey in this.results.five_pillars_v2) {
        fivePillars.push({
          name: pillarKey,
          version: 2,
          ...this.results.five_pillars_v2[pillarKey]
        });
      }
    } else {
      for (const pillarKey in bc?.five_pillars) {
        fivePillars.push({
          name: pillarKey,
          version: 1,
          ...bc?.five_pillars[pillarKey],
        });
      }
    }

    const personalProfile = bc?.result_profiles?.filter((p) => p.type === 'a')[0] ?? null;
    const improvementProfile = bc?.result_profiles?.filter((p) => p.type === 'b')[0] ?? null;

    let personalGraph = [];
    let improvementGraph = [];

    if (bc?.neurotransmitters_normalized?.a) {
      for (const key of Object.keys(bc.neurotransmitters_normalized.a)) {
        personalGraph.push({
          value: bc.neurotransmitters_normalized.a[key] * 100,
          color: getNeurotransmitterColor(key),
          label: key === 'gaba' ? 'GABA': (key.charAt(0).toUpperCase() + key.slice(1))
        });
      }

      personalGraph = this.sortNeurotransmitters(personalGraph);
    } else {
      personalGraph = this.#sampleGraph;
    }
    if (bc?.neurotransmitters_normalized_combination) {
      for (const key of Object.keys(bc.neurotransmitters_normalized_combination)) {
        const {performance, potential} = bc.neurotransmitters_normalized_combination[key];
        improvementGraph.push({
          value: potential*100,
          oldValue: performance*100,
          color: getNeurotransmitterColor(key),
          label: key === 'gaba' ? 'GABA': (key.charAt(0).toUpperCase() + key.slice(1))
        });

        improvementGraph = this.sortNeurotransmitters(improvementGraph);
      }
    } else {
      improvementGraph = this.#sampleGraph;
    }

    // Levels.
    const neurotransmitterLevels = bc?.neurotransmitters_levels;

    return html`

        ${this.renderVectorStat(coqnitiveScore, "cognitive-performance")}

        <!--        <section class="card dark-green">-->
        <!--          <h2>Check our video for more information</h2>-->
        <!--          <video-player-->
        <!--            url="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"></video-player>-->
        <!--        </section>-->

        ${this.renderVectorStat(relaxRecoverScore, "relax-recover")}

        <section class="card">
          <h2>Your overall score</h2>
          <p>
            Based on the answers to the braincheck, these are your scores based
            on energy, sleep, focus, stress management and mental resilience.
          </p>

          ${repeat(
            fivePillars,
            (pillar, idx) => {
              const name = pillar.name
                .replace("_", " ")
                .toLowerCase()
                .split(" ")
                .map(function (word) {
                  return word.charAt(0).toUpperCase() + word.slice(1);
                })
                .join(" ");
              const colors = ["red", "yellow", "green", "blue"];
              const color = colors[idx % colors.length];

              return html`
                <section class="card">
                  <h4 class="center">${name}</h4>
                  <progress-indicator
                    type="thick-line"
                    theme="${color}"
                    value="${pillar.version === 2 && pillar.combined_score ? pillar.combined_score * 10 : pillar.normalized * 10}"
                    max="10"
                    label=""
                    size=""
                    bg=""
                  >
                  </progress-indicator>
                </section>
              `;
            }
          )}
        </section>

        <section class="card dark-green">
          <h2>Personal neurotransmitter profile</h2>
          <p>
            This graph shows the dominance of neurotransmitters and associated
            personal traits See the graph below.
          </p>

          <section class="card">
            <h4 class="center">Personal characteristics</h4>
            ${personalGraph ? html`<column-chart max=100 .data=${personalGraph}></column-chart>` : nothing}
          </section>
        </section>

        ${personalProfile ? html`
          <section class="card">
            <h2>Your natural personal characteristics</h2>
            <translatable-content current-language="en">
              <p>${personalProfile.subtext ?? personalProfile.name}</p>
            </translatable-content>

            ${repeat(personalProfile.texts, (text, idx) => {
              const colors = ["red", "yellow", "green", "blue"];
              const color = colors[idx % colors.length];
              const {name, value} = text;

              return html`
                <details class="${color}" ?open=${name.toLowerCase() === 'to summarize'}>
                  <summary>${name}</summary>
                  <!-- Content -->
                  <section>
                    <translatable-content current-language="en">
                      ${unsafeHTML((new Converter()).makeHtml(value))}
                    </translatable-content>
                  </section>
                </details>
              `;
            })}
          </section>
        ` : nothing}

        <section class="card dark-green">
          <h2>Current state vs. maximum potential</h2>

          <section class="card">
            ${improvementGraph ? html`<column-chart max=100 .data=${improvementGraph} type="beside"></column-chart>` : nothing}
          </section>
        </section>

        ${improvementProfile ? html`
          <section class="card">
            <h2>Areas of cognitive improvement</h2>

            <figure class="center mb-small">
              <img
                src="/assets/img/balance.svg"
                alt="Girl balancing"
                loading="lazy"
              />
            </figure>


            ${improvementProfile.subtext ? html`
              <translatable-content current-language="en">
                ${unsafeHTML((new Converter()).makeHtml(improvementProfile.subtext))}
              </translatable-content>
            ` : nothing}

            ${repeat(improvementProfile.texts, (text, idx) => {
              const colors = ["red", "yellow", "green", "blue"];
              const color = colors[idx % colors.length];
              const {name, value} = text;

              return html`
                <details class="${color}">
                  <summary>${name}</summary>
                  <!-- Content -->
                  <section>
                    <translatable-content current-language="en">
                      ${unsafeHTML((new Converter()).makeHtml(value))}
                    </translatable-content>
                  </section>
                </details>
              `;
            })}

            <details class="blue">
              <summary>Qogni program</summary>
              <!-- Content -->
              <section>
                <p>
                  <translatable-content current-language="en">
                  ${improvementProfile.gaba && improvementProfile.serotonin && improvementProfile.dopamine && improvementProfile.acetylcholine ? html`
                    While you're already enjoying a state of mental and emotional balance, Qogni offers a program to help maintain and further enhance this harmony. If you're interested in optimizing your cognitive and emotional prowess even more, ensuring that you remain at your peak, you can delve into our specialized program below.
                  ` : html`
                    If you recognize these signs, qogni has a tailored program based on these outcomes. If you're interested in
                    boosting cognitive performance and getting on top of the world, you can activate your personal program below.
                  `}
                  </translatable-content>
                </p>
              </section>
            </details>
          </section>
        ` : nothing}

        ${neurotransmitterLevels ? html`
          ${repeat(Object.keys(neurotransmitterLevels), (neuro, idx) => {
            return html``;
            // const level = neurotransmitterLevels[neuro];
            // const colors = ["red", "yellow", "green", "blue"];
            // const color = colors[idx % colors.length];
            // const name = neuro === 'gaba' ? 'GABA': (neuro.charAt(0).toUpperCase() + neuro.slice(1))
            //
            // return html`
            //   <details class="${color} large dark-active">
            //     <summary>
            //       ${name} <badge-tag class="beige">${level.title}</badge-tag>
            //     </summary>
            //     <!-- Content -->
            //     <section ?hidden=${! level.text && ! level.description}>
            //       <section class="card">
            //         <p>
            //           <strong>${level.text}</strong>
            //         </p>
            //         <p>
            //           ${level.description}
            //         </p>
            //       </section>
            //     </section>
            //   </details>
            // `;
          })
        }
        ` : nothing}

        ${bc.neurotransmitters_values ? html`
          <details class="large dark-active">
            <summary>
              Debug Info: Neurotransmitter Values
            </summary>
            <!-- Content -->
            <section>
              <section class="card">
                <pre>${JSON.stringify(bc.neurotransmitters_values, null, 4)}</pre>
              </section>
            </section>
          </details>
        ` : nothing}
        ${bc.neurotransmitters_maximums ? html`
          <details class="large dark-active">
            <summary>
              Debug Info: Neurotransmitter Maximums
            </summary>
            <!-- Content -->
            <section>
              <section class="card">
                <pre>${JSON.stringify(bc.neurotransmitters_maximums, null, 4)}</pre>
              </section>
            </section>
          </details>
        ` : nothing}

        ${availableProgram ? html`
          <section class="card red">
            <h2>
              ${availableProgram?.product?.name ?? availableProgram?.name}
            </h2>

            <translatable-content current-language="en" style="--translate-button-color: white; --translate-icon-color: white;">
              <p>
                ${availableProgram?.product?.description ?
                  unsafeHTML(new Converter().makeHtml(availableProgram?.product?.description))
                : availableProgram.description}
              </p>
            </translatable-content>

            ${this.hasActiveProgram ? html`
              <p>
                <strong>This program is already activated. Head over to your program to see your progress and day-overview.</strong>
              </p>
              <a class="button wide white" href="/program">
                Go to my program
              </a>
            ` : html`
              <a class="button wide white" href="/program/activation">
                Activate my program today
              </a>
            `}
          </section>
        ` : nothing}
    `;
  }

  renderBrainCheckDefaults() {
    return html`
        ${this.renderVectorStat(null, "cognitive-performance")}
        ${this.renderVectorStat(null, "relax-recover")}

        <section class="card dark-green">
          <h2>Personal neurotransmitter profile</h2>
          <p>
            This graph shows the dominance of neurotransmitters and associated
            personal traits See the graph below.
          </p>

          <section class="card">
            <h4 class="center">Personal characteristics</h4>
          </section>
        </section>

        <section class="card">
          <h2>Your overall score</h2>
          <p>
            Based on the answers to the braincheck, these are your scores based
            on energy, sleep, focus, stress management and mental resilience.
          </p>
        </section>

        <section class="card dark-green">
          <h2>Personal improvement potential</h2>

          <section class="card">
            <column-chart max=100 .data=${this.#sampleGraph}></column-chart>
          </section>
        </section>

        <details class="blue large dark-active">
          <summary>
            Serotonin
            <badge-tag class="beige">Sufficient</badge-tag>
          </summary>
          <!-- Content -->
          <section>
            <section class="card">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Assumenda iste mollitia numquam ullam? Consectetur esse facere
                minima perspiciatis rerum temporibus.
              </p>
            </section>
          </section>
        </details>

        <details class="red large dark-active">
          <summary>
            Dopamin
            <badge-tag class="beige">Sufficient</badge-tag>
          </summary>
          <!-- Content -->
          <section>
            <section class="card">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Assumenda iste mollitia numquam ullam? Consectetur esse facere
                minima perspiciatis rerum temporibus.
              </p>
            </section>
          </section>
        </details>

        <details class="green large dark-active">
          <summary>
            GABA
            <badge-tag class="beige">Sufficient</badge-tag>
          </summary>
          <!-- Content -->
          <section>
            <section class="card">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Assumenda iste mollitia numquam ullam? Consectetur esse facere
                minima perspiciatis rerum temporibus.
              </p>
            </section>
          </section>
        </details>

        <details class="yellow large dark-active">
          <summary>
            Acetylcholine
            <badge-tag class="beige">Sufficient</badge-tag>
          </summary>
          <!-- Content -->
          <section>
            <section class="card">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Assumenda iste mollitia numquam ullam? Consectetur esse facere
                minima perspiciatis rerum temporibus.
              </p>
            </section>
          </section>
        </details>

        <section class="card red">
          <h2>
            Sample Program
          </h2>

          <p>
            This program will elevate your current performance... Any program will come here on your personal result
            page. Make sure you finish the BrainCheck first however!
          </p>

          <a class="button wide white" href="/program">
            Activate my program today
          </a>
        </section>
    `;
  }

  renderHealthCheck(hc) {
    return html`
      <div class="${!hc ? "preview-wrap" : ""}">
        ${!hc || !hc.totals
          ? html`
            <div class="preview-content">
              <badge-tag class="mb-tiny blue small">
                HealthCheck Results
              </badge-tag>

              <h1>HealthCheck not finished!</h1>
              <p>
                You haven't completed the HealthCheck before. In order to see
                your personalized health status, head over to the HealthCheck
                and complete it!
              </p>

              <flex-container class="justify-content-center">
                <flex-item>
                  <a href="/healthcheck" class="button green wide"
                  >Start HealthCheck</a
                  >
                </flex-item>
              </flex-container>
            </div>
          `
          : nothing}

        ${this.renderHealthCheckStats(hc)}
      </div>
    `;
  }

  renderHealthCheckStats(hc) {
    if (! hc || ! hc.totals) return this.renderHealthCheckDefaults();
    const colors = ["red", "yellow", "green", "blue"];

    return html`
      <section class="card dark-green">
        <h2>HealthCheck</h2>
        <p>
          Based on the answers to the HealthCheck, the below topics are most relevant to you.
          Based on these outcomes, Qogni will offer customised content.
        </p>

        ${repeat(hc.totals, (res, idx) => {
          const color = colors[idx % colors.length];
          return html`
            <section class="card">
              <details class="simple nested">
                <summary>
                  <progress-indicator
                    theme="${color}"
                    label="${res.question_category.name}"
                    value="${res.total}"
                    max="${res.out_of}"
                    hide-out-of
                    type="line"
                  ></progress-indicator>
                  <svg-icon icon="caret"></svg-icon>
                </summary>
                <!-- Content -->
                <section>
                  ${res.question_category?.description ? html`
                    <translatable-content current-language="en">
                      ${unsafeHTML((new Converter()).makeHtml(res.question_category.description))}
                    </translatable-content>
                  ` : nothing}
                  ${res.questions && res.questions?.length > 0 ? html`
                    <strong>Questions connected to ${res.question_category.name}:</strong>
                    <ul class="mt-none">
                      ${repeat(res.questions, (q) => html`
                        <li>${q}</li>
                      `)}
                    </ul>
                  ` : nothing}
                </section>
              </details>
            </section>
          `;
        })}
      </section>
    `;
  }

  renderHealthCheckDefaults() {
    return html`
      <section class="card dark-green">
        <h2>HealthCheck</h2>
        <p>
          Based on the answers to the HealthCheck, the below topics are most relevant to you.
          Based on these outcomes, Qogni will offer customised content.
        </p>

        <section class="card">
          <progress-indicator
            theme="yellow"
            label="Cortisol high"
            value="9"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="red"
            label="Magnesium"
            value="11"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="blue"
            label="Cortisol low"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="green"
            label="Intolerances and allergies"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="yellow"
            label="Cortisol high"
            value="9"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="red"
            label="Magnesium"
            value="11"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="blue"
            label="Cortisol low"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="green"
            label="Intolerances and allergies"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="yellow"
            label="Cortisol high"
            value="9"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            theme="red"
            label="Magnesium"
            value="11"
            max="12"
            type="line"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="blue"
            label="Cortisol low"
            value="4"
            max="12"
          ></progress-indicator>
        </section>

        <section class="card">
          <progress-indicator
            type="line"
            theme="green"
            label="Intolerances and allergies"
            value="4"
            max="12"
          ></progress-indicator>
        </section>
      </section>
    `;
  }
}

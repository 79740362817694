import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { Task } from "@qogni-technologies/design-system/src/shared/task";
import { html, nothing } from "lit";
import { AccountDomain } from "../../../domain/account-domain";
import { CircleDomain } from "../../../domain/circle-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../shared/pwa-page";
import "../../../shared/web-components/manage-users";

const GraphLabels = {
  sleep: "Sleep",
  energy: "Energy",
  focus: "Focus",
  cognitive_performance: "Cognitive potential",
  relax_recover: "Relax & Recover Ability",
  stress_management: "Stress Resilience",
  mental_resilience: "Mental Resilience",
};

export class PageSingleCircle extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;
  #accountDomain;

  static get properties() {
    return {
      circleId: { type: Number },
      circle: { type: Object },
      connectionsList: { type: Array },
      computedConnectionsList: { type: Array },
      addUser: { type: Boolean },
      circleGraph: { type: Boolean },
      circleGraphAvailable: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new CircleDomain();
    this.#accountDomain = new AccountDomain();
    this.addUser = false;
    this.circleGraphAvailable = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.circleId = app.activeRoute.patternResult.pathname.groups.id;
    if (this.circleId) {
      this.#getCircleDetails();
      this.#getConnections();
      this.#getCircleGraph();
    }
  }

  async #getCircleDetails() {
    const task = async () => {
      this.circle = await this.#domain.getSingleCircle(this.circleId);
    };

    Task.run(task, {
      ghost: this,
      description: "Loading circle details...",
    });
  }

  async #getConnections() {
    const task = async () => {
      const response = await this.#accountDomain.getConnections({
        per_page: 250,
      });
      this.connectionsList = response.data;
    };

    await Task.run(task, {
      ghost: this,
      description: "Loading users.",
    });
  }

  async #getCircleGraph() {
    const task = async () => {
      const response = await this.#domain.getCircleGraph(this.circleId);
      if (!response.status) this.circleGraphAvailable = false;
      this.circleGraphAvailable = true;
      this.circleGraph = response.data;
    };

    await Task.run(task, {
      ghost: this,
      description: "Loading graph...",
    });
  }

  async #addSelectedUser(e) {
    const user = e.detail;
    if (! user || ! user.id) return;

    const userId = user.id;
    const userName = `${user.firstname} ${user.lastname}`;

    const task = async () => {
      const res = await this.#domain.addUserToCircle(this.circleId, userId);
      if (res) {
        app.addToastMessage(`${userName} added to the circle successfully`);
        await this.#refreshCircle();
      }
      this.addUser = false;
    };

    Task.run(task, {
      ghost: this,
      description: "Adding new user.",
    });
  }

  async #refreshCircle() {
    await this.#getCircleDetails();
    await this.#getCircleGraph();
  }

  async #onRemoveUser(user) {
    const task = async () => {
      const { id, firstname, lastname } = user;
      const userConfirmed = await askConfirm({
        title: "Remove User?",
        message: `Are you sure you want to remove <b>${firstname} ${lastname}</b> from circle?`,
      });

      if (!userConfirmed) return;

      await this.#domain.removeUserFromCircle(this.circleId, id);
      await this.#refreshCircle();
    };

    await Task.run(task, {
      ghost: this,
      description: "Removing user...",
    });
  }

  async #deleteCircle() {
    const task = async () => {
      const { id, name } = this.circle;
      const userConfirmed = await askConfirm({
        title: "Delete circle?",
        message: `Are you sure you want to delete <b>${name}</b> circle?`,
        okText: "Delete",
      });

      if (!userConfirmed) return;

      const res = await this.#domain.deleteCircle(id);
      if (res) {
        window.location.replace("/network?section=circles");
      }
    };

    await Task.run(task, {
      ghost: this,
      description: "Deleting circle...",
    });
  }

  #onUserAction(e) {
    const { action, user } = e.detail;
    if (action === "remove") this.#onRemoveUser(user);
  }

  willUpdate(changeProps) {
    if (
      this.connectionsList &&
      this.circle &&
      (changeProps.has("circle") || changeProps.has("connectionsList"))
    ) {
      const { users } = this.circle;
      const usersId = users.map((e) => e.id);
      this.computedConnectionsList = this.connectionsList.filter(
        (e) => !usersId.includes(e.id)
      );
    }
  }

  render() {
    if (!this.circle) {
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer class="tiny"></app-shimmer>
        <app-shimmer class="image"></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="title tiny"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
      `;
    }

    const { name, users } = this.circle;

    return html`
      <div class="center">
        <h1>Circle details</h1>

        <circle-link class="red">
          <a>
            <h3>${name}</h3>
            <p>${users.length} members</p>
          </a>
        </circle-link>

        ${users.length <= 50
          ? html`
              <button
                class="white wide mb-small"
                @click=${() => (this.addUser = !this.addUser)}
              >
                <svg-icon icon="account-add"></svg-icon>
                Add people to this circle
              </button>
            `
          : nothing}
        ${this.addUser && users.length <= 50
          ? html`
              <form class="circle-add-new-user-form">
                <label>
                  <span data-label="">Find connections</span>
                  <connections-autocomplete
                    @result-selected=${this.#addSelectedUser}
                    allow-invitations
                    object-name="Circle"
                    object-type="circles"
                    object-id="${this.circle.id}"
                    .userList=${this.computedConnectionsList}
                  ></connections-autocomplete>
                </label>
              </form>
            `
          : nothing}
        ${this.#renderUsers()} ${this.#renderCircleGraph()}

        <h2>Wow! You are doing a great job based on your statistics.</h2>

        <figure class="center mb-small">
          <img
            src="/assets/img/balance.svg"
            alt="Girl balancing"
            loading="lazy"
          />
        </figure>

        <p>
          You are currently doing better than any other user on Qogni. Keep this
          up and keep developing yourself.
        </p>

        <!-- <section class="card">
          <h3 class="center">Score of everyone using Qogni</h3>
          Graph here
        </section> -->

        ${this.#renderDeleteCirlce()}
      </div>
    `;
  }

  #renderCircleGraph() {
    if (!this.circleGraph && !this.circleGraphAvailable) {
      return html`
        <section class="card">
          <app-shimmer class="title"></app-shimmer>
          <app-shimmer class="tiny"></app-shimmer>
          <app-shimmer class="image"></app-shimmer>
        </section>
      `;
    }

    if (!this.circleGraph & this.circleGraphAvailable) {
      const dummyGraphData = Object.entries(GraphLabels).map((item) => {
        const value = Math.random() * 100;
        const oldValue = Math.random() * 100;
        return {
          value,
          oldValue,
          label: item[1],
          color: "#64C8FF",
          oldColor: "var(--color-primary-300)",
        };
      });

      return html`
        <section class="card graph ">
          <h3 class="center">Score of everyone in this circle</h3>
          <div class="graph-overview">
            <span class="your-score"
              ><figure></figure>
              Your score</span
            >
            <span class="other"
              ><figure></figure>
              Average of persons in circle</span
            >
          </div>
          <column-chart .data=${dummyGraphData}></column-chart>
          <div class="preview-content">
            <h1>Graph not available</h1>
            <p>Benchmark threshold: 5 people with completed BrainChecks.</p>
          </div>
        </section>
      `;
    }

    const { my, average } = this.circleGraph;

    const graphData = Object.entries(GraphLabels).map(([key, label]) => {
      const value = my[key] < 0 ? 0 : my[key] * 100;
      const oldValue = average[key] < 0 ? 0 : average[key] * 100;
      return {
        value,
        oldValue,
        label,
        color: "#64C8FF",
        oldColor: "var(--color-primary-300)",
      };
    });

    return html`
      <section class="card graph">
        <h3 class="center">Score of everyone in this circle</h3>
        <div class="graph-overview">
          <span class="your-score"
            ><figure></figure>
            Your score</span
          >
          <span class="other"
            ><figure></figure>
            Average of persons in circle</span
          >
        </div>
        <column-chart max=100 .data=${graphData}></column-chart>
      </section>
    `;
  }

  #renderUsers() {
    const { users } = this.circle;
    const usersActions = [
      { name: "remove", label: "Remove", icon: "trash", theme: "red" },
    ];

    return html`
      <manage-users
        .heading=${"People in circle"}
        .users=${users}
        .actions=${usersActions}
        allowAction
        @action=${this.#onUserAction}
      ></manage-users>
    `;
  }

  #renderDeleteCirlce() {
    return html`
      <details class="bg-white red">
        <summary>Danger zone</summary>
        <section class="card">
          <button
            name="delete"
            class="primary wide red"
            type="button"
            @click=${this.#deleteCircle}
          >
            Delete circle
          </button>
        </section>
      </details>
    `;
  }
}

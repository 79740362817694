import {css} from "lit";
import {ButtonBarAppearance} from "@qogni-technologies/design-system/src/shared/common";
import {ButtonBar} from "./button-bar";
import {SharedStyles} from "../shared-styles.css";

/**
 * Customizable bottom bar component
 */
customElements.define(
  "bottom-bar",
  class BottomBar extends ButtonBar {
    static get properties() {
      return {
        buttons: {},
        global: {type: Boolean, attribute: true},
        appearance: {type: ButtonBarAppearance, attribute: true},
      };
    }

    static get styles() {
      return [
        SharedStyles,
        css`
          :host {
            display: block;
            min-width: 300px;
          }

          nav {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 80px;
            background: var(--color-primary-000);
            border-radius: var(--radius-large) var(--radius-large) 0 0;
            padding: 0 var(--gutter-small);
            box-sizing: border-box;
          }

          a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            font-size: 0;
            --icon-fill-color: var(--color-primary-200);

            &:hover,
            &:focus,
            &.active {
              --icon-fill-color: var(--color-accent-400);
            }
          }

          a.primary {
            width: 80px;
            height: 80px;
            background: var(--color-primary-200);
            border: 3px solid var(--color-primary-000);
            display: flex;
            margin-bottom: 50px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            --icon-fill-color: var(--color-primary-000);
            transition: background var(--transition-duration) ease-in-out;

            &:hover,
            &:focus,
            &.active,
            &.highlighted {
              background: var(--color-accent-400);
              --icon-fill-color: var(--color-primary-200);
            }
          }

          a.primary figure {
            width: 40%;
            height: 40%;
          }

          figure {
            margin: 0;
            display: block;
            width: 75%;
            height: 75%;
          }

          nav.swipe-cards {
            position: static;
            background: transparent;
            justify-content: center;
          }

          nav.swipe-cards a {
            background: var(--color-primary-000);
            width: 80px;
            height: 80px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            --icon-fill-color: var(--color-primary-200);
            margin: 0 10px;
          }

          nav.swipe-cards a.small {
            width: 60px;
            height: 60px;
          }

          nav.swipe-cards a figure {
            width: 50%;
            height: 50%;
          }
        `,
      ];
    }

    navClick(e) {
      const id = e.target.closest("a")?.getAttribute("data-id");
      if (id === "menu") {
        e.preventDefault();
        return app.fire("toggle-side-menu");
      }

      return super.navClick(e);
    }
  }
);

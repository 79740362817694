import {
  askConfirm,
  showAlert,
} from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { Task } from "@qogni-technologies/design-system/src/shared/task";
import { LitElement, html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { Converter } from "showdown";
import { AccountDomain } from "../../domain/account-domain";
import { PostDomain } from "../../domain/post-domain";
import { TimelineDomain } from "../../domain/timeline-domain";
import { config } from "../../qogni-app-config";
import { nativeWebShare } from "../common";
import "./edit-post-dialog";

export class PostEntry extends LitElement {
  createRenderRoot() {
    return this;
  }

  #domain;
  #postDomain;
  #accountDomain;
  #nextCommentCursor = null;
  #commentRichEditorRef = createRef();

  static get properties() {
    return {
      index: { type: Number },
      postId: { type: String },
      for: { type: String },

      // Timeline uses
      entry: { type: Object },

      // Post
      post: { type: Object },

      // Option
      option: { type: Object },

      // User
      user: { type: Object },

      // Statstics
      stats: { type: Object },

      showComments: { type: Boolean },
      comments: { type: Array },
      commentsLoading: { type: Boolean },
      commentToPost: { type: Boolean },

      selfFetch: { type: Boolean, attribute: "self-fetch" },

      // For saved items page
      saveId: { type: String },
      unsave: { type: Boolean },

      // Edit content
      _editDialogOpened: { type: Boolean },
      editAllowed: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.#domain = new TimelineDomain();
    this.#postDomain = new PostDomain();
    this.#accountDomain = new AccountDomain();
    this.comments = [];
  }

  async connectedCallback() {
    super.connectedCallback();

    if (this.selfFetch && this.postId) {
      await this.#getPost();
    }

    // Load comments for first post
    if (this.index === 0 && this.stats?.comments !== 0) {
      this.showComments = true;
      this.#loadComments();
    }
  }

  willUpdate(changeProps) {
    if (changeProps.has("entry") && this.entry) {
      this.post = this.entry.post;
      this.repost = this.entry.repost;
      this.option = this.entry.option;
    }

    if (changeProps.has("post") && this.post) {
      this.postId = this.post?.id;
    }

    if (this.post && this.post?.user) {
      this.user = this.post?.user;
    }

    if (this.repost && this.repost?.post?.user) {
      this.user = this.repost.post.user;
    }

    if (this.option && this.option?.user) {
      this.user = this.option.user;
    }
  }

  async #getPost() {
    const task = async () => {
      this.post = await this.#postDomain.getPost(this.postId);
    };

    await Task.run(task, {
      ghost: this,
      description: "Loading post...",
    });
  }

  updated(changeProps) {
    if (
      changeProps.has("entry") ||
      changeProps.has("post") ||
      changeProps.has("repost") ||
      changeProps.has("option")
    ) {
      setTimeout(() => {
        app.enhancers.run(this);
      });
    }
  }

  async #onShare(post) {
    const task = async () => {
      const { id, user } = post;
      const { firstname, lastname } = user;
      const { absoluteUrl } = config;
      const link = `${absoluteUrl}/posts/${id}`;
      const title = `${firstname} ${lastname}'s post on Qogni`;

      await nativeWebShare({ title, text: link });
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }

  async #onTipOfDayShare(option) {
    const task = async () => {
      const { name } = option;
      const text =
        "This tip is offered on the Qogni App, your personal Health App. Get started at https://app.qogni.io";

      await nativeWebShare({ title: name, text });
    };

    Task.run(task, {
      ghost: this,
      global: false,
    });
  }

  async #onDelete(post) {
    const task = async () => {
      const userConfirmed = await askConfirm({
        title: "Delete Post?",
        message: "Are you sure you want to delete your post?",
      });

      if (!userConfirmed) return;

      await this.#domain.deletePost(post.id);
      this.dispatchEvent(new CustomEvent("refresh"));
    };

    await Task.run(task, {
      ghost: this,
      description: "Deleting post...",
    });
  }

  async #likePost(typeCode, type) {
    const task = async () => {
      const oldLike = this.post?.like || this.repost?.post?.like;
      if (oldLike) {
        // Unlike
        this.stats[oldLike.type_code]--;
        if (this.post) this.post.like = null;
        if (this.repost?.post) this.repost.post.like = null;
      }
      if (!oldLike || oldLike.type !== type) {
        // Like
        const userId = this.post?.user_id || this.repost?.user_id;
        this.stats[typeCode]++;
        const like = {
          user_id: userId,
          type: type,
          type_code: typeCode,
        };
        if (this.post) this.post.like = like;
        if (this.repost?.post) this.repost.post.like = like;
      }
      this.requestUpdate();

      const postId = this.post.id;
      await this.#domain.likePost(postId, type);
    };

    return Task.run(task, {
      ghost: this,
      description: "Like post",
    });
  }

  async #loadComments() {
    const query = {
      ...(this.#nextCommentCursor && { cursor: this.#nextCommentCursor }),
    };
    const postId = this.post?.id || this.repost?.post?.id;
    const task = async () => {
      try {
        if (!postId) return;
        this.commentsLoading = true;
        const response = await this.#domain.getComments(postId, { query });

        if (this.#nextCommentCursor) {
          this.comments = [...(this.comments ?? []), ...response.data];
        } else {
          this.comments = response.data;
        }
        this.commentsPagination = response.pagination;
        this.#nextCommentCursor = response.pagination.next_cursor;
        this.commentsLoading = false;
      } catch (err) {
        app.addToastMessage(`Timeline Comments: ${err}`, { type: "error" });
      }
      this.requestUpdate();
    };

    await Task.run(task);
  }

  async #addComment() {
    const task = async () => {
      const commentContent = this.#commentRichEditorRef.value.text;

      if (!commentContent) {
        return showAlert({ title: "Please enter text for your comment!" });
      }

      const postId = this.post?.id || this.repost?.post?.id;
      await this.#domain.addComment(postId, commentContent);
      this.stats.comments++;

      // Open comments section
      this.showComments = true;
      this.#nextCommentCursor = null;
      this.#loadComments();

      // close comment write section
      this.commentToPost = false;

      app.addToastMessage("Comment posted successfully!");
      this.requestUpdate();
    };

    await Task.run(task, {
      ghost: this,
      description: "Adding comment to post...",
    });
  }

  async #sharePost() {
    const postId = this.post.id;

    return this.#repostPost(postId);
    // todo: share action.
  }

  async #repostPost(postId) {
    const task = async () => {
      try {
        await this.#domain.repostPost(postId);
      } catch (e) {
        if (e.response?.code === 409)
          return app.addToastMessage("You already reposted this post before!", {
            type: "error",
          });
        return app.addToastMessage("Error with reposting post!", {
          type: "error",
        });
      }

      // TODO: Set repost flag in the entries locally.
    };
    return Task.run(task, {
      ghost: this,
      description: "Reposting post...",
    });
  }

  async #onSave(id, key) {
    const task = async () => {
      const res = await this.#accountDomain.saveItemToSavedList({ [key]: id });
      if (!res.status) return false;
      app.addToastMessage("Saved successfully.");
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  async #onUnsave() {
    const task = async () => {
      const userConfirmed = await askConfirm({
        title: "Confirm Unsave",
        message: "Are you sure you want to unsave this post?",
        okText: "Confirm",
      });
      if (!userConfirmed) return;

      await this.#accountDomain.unsaveItemToSavedList(this.saveId);
      this.dispatchEvent(new CustomEvent("refresh"));
    };

    Task.run(task, {
      ghost: this,
    });
  }

  async #onEdit() {
    this._editDialogOpened = true;
  }

  async #onEditSuccess() {
    const task = async () => {
      await this.#getPost();
    };

    await Task.run(task, {
      ghost: this,
    });
  }

  render() {
    if (!this.post && !this.repost && !this.option) {
      return html`
        <section class="card">
          <app-shimmer class="title"></app-shimmer>
          <app-shimmer class="tiny"></app-shimmer>
          <app-shimmer class="mb"></app-shimmer>
          <app-shimmer class="image"></app-shimmer>
        </section>
      `;
    }

    if (this.post) return this.renderPostEntry(this.entry);
    if (this.repost) return this.renderPostEntry(this.entry);
    if (this.option) return this.renderTipOfTheDayEntry(this.entry);
    return nothing;
  }

  renderPostEntry(entry) {
    const post = this.post ?? this.repost.post;
    const isRepost = !!this.repost;
    const content = post.content;
    const image = post?.header_image_urls;
    const imageUrl = image?.jpg;

    if (!content) return nothing;

    let entryDate = null;
    let postDate = null;
    let entryDateFormatted = null;
    let postDateFormatted = null;
    if (entry?.created_at) {
      try {
        entryDate = new Date(entry.created_at);
        entryDateFormatted = Intl.DateTimeFormat(navigator.languages, {
          dateStyle: "short",
          timeStyle: "short",
        }).format(entryDate);
      } catch {
        console.warn("Can't parse date: " + entry.created_at);
        // ignore
      }
    }
    if (post.created_at) {
      try {
        postDate = new Date(post.created_at);
        postDateFormatted = Intl.DateTimeFormat(navigator.languages, {
          dateStyle: "short",
          timeStyle: "short",
        }).format(postDate);
      } catch {
        console.warn("Can't parse date (post): " + post.created_at);
        // ignore
      }
    }

    return html`
      ${isRepost
        ? html`
            <section class="card repost">
              <flex-container class="align-items-center">
                <flex-item>
                  <badge-tag class="yellow">Repost</badge-tag>
                </flex-item>

                <flex-item
                  class="time-ago"
                  title="${entryDateFormatted ?? "?"}"
                >
                  ${entryDate
                    ? entryDate.format({
                        mode: "auto",
                        timeStyle: undefined,
                        rangeDays: 2,
                      })
                    : ""}
                </flex-item>

                <flex-item>
                  <a href="/profile/${this.repost?.user_id}">
                    <article-author>
                      <figure>
                        <img
                          src="${this.repost.user?.profile_img_url ??
                          "/assets/img/profile-picture.webp"}"
                          alt="User profile picture"
                          loading="lazy"
                        />
                      </figure>

                      ${entry.repost?.user
                        ? html`
                            <div class="info">
                              <strong
                                >${entry.repost.user.firstname}
                                ${entry.repost.user.lastname}</strong
                              >
                            </div>
                          `
                        : nothing}
                    </article-author>
                  </a>
                </flex-item>
              </flex-container>
            </section>
          `
        : nothing}
      <section
        class="card post ${isRepost ? "reposted" : ""}"
        data-comments=${this.stats?.comments ?? 0}
        data-id=${post.id}
        data-timeline-id="${entry?.id}"
      >
        <flex-container class="align-items-center">
          <flex-item>
            <badge-tag class="red">Post</badge-tag>
          </flex-item>

          <flex-item title="${postDateFormatted ?? "?"}">
            ${postDate
              ? postDate.format({
                  mode: "auto",
                  dateStyle: "long",
                  timeStyle: undefined,
                  rangeDays: 2,
                })
              : nothing}
          </flex-item>

          <nav id="account" data-dropdown class="align-right img-button">
            <button title="Menu" class="simple">
              <svg-icon icon="meatballs"></svg-icon>
            </button>
            <menu>
              ${!this.unsave
                ? html`
                    <li>
                      <a @click=${() => this.#onSave(post.id, "post_id")}>
                        <svg-icon icon="save" size="20px"></svg-icon>
                        Save
                      </a>
                    </li>
                  `
                : nothing}
              ${this.unsave
                ? html`
                    <li>
                      <a @click=${this.#onUnsave}>
                        <svg-icon icon="save" size="20px"></svg-icon>
                        Unsave
                      </a>
                    </li>
                  `
                : nothing}
              ${this.editAllowed
                ? html`
                    <li>
                      <a @click=${this.#onEdit}>
                        <svg-icon icon="pencil" size="20px"></svg-icon>
                        Edit
                      </a>
                    </li>
                  `
                : nothing}
              <li>
                <a @click=${() => this.#onShare(post)}>
                  <svg-icon
                    icon="${navigator.canShare ? "share" : "copy"}"
                    size="20px"
                  ></svg-icon>
                  ${navigator.canShare ? "Share" : "Copy Link"}
                </a>
              </li>
              ${this.editAllowed
                ? html`<li class="delete">
                    <a @click=${() => this.#onDelete(post)}
                      ><svg-icon icon="trash" size="20px"></svg-icon>Delete</a
                    >
                  </li>`
                : nothing}
            </menu>
          </nav>
        </flex-container>

        <article-author>
          <a
            href="/profile/${this.post?.user_id || this.repost?.post?.user_id}"
          >
            <figure>
              <img
                src="${this.user?.profile_img_url ??
                "/assets/img/profile-picture.webp"}"
                alt="User profile picture"
                loading="lazy"
              />
            </figure>
          </a>

          ${this.user
            ? html`
                <div class="info">
                  Posted by
                  <a
                    href="/profile/${this.post?.user_id ||
                    this.repost?.post?.user_id}"
                  >
                    <strong
                      >${this.user.firstname} ${this.user.lastname}</strong
                    >
                  </a>
                  ${this.for !== "channel-detail" &&
                  this.post?.channel &&
                  this.post?.channel?.name
                    ? html`
                        in
                        <a
                          class="link"
                          href="/network/channels/${this.post.channel.id}"
                          ><strong>${this.post.channel.name}</strong></a
                        >
                      `
                    : nothing}
                </div>
              `
            : nothing}
        </article-author>

        <translatable-content
          current-language="${post.language}"
          content-name="post"
        >
          ${unsafeHTML(
            new Converter({ openLinksInNewWindow: true }).makeHtml(content)
          )}
        </translatable-content>

        ${imageUrl
          ? html`
              <figure class="article-image">
                <img src="${imageUrl}" loading="lazy" alt="post image" />
              </figure>
            `
          : nothing}
        ${this.#renderSocialInteraction(post)}
      </section>
      ${this.#renderFragments()}
    `;
  }

  #renderFragments() {
    if (this._editDialogOpened) {
      return html`<edit-post-dialog
        open
        .post=${this.post}
        @close=${() => (this._editDialogOpened = false)}
        @success=${this.#onEditSuccess}
      ></edit-post-dialog>`;
    }

    return nothing;
  }

  #renderSocialInteraction(post) {
    if (!this.stats) return nothing;

    let lastEditedDate = null;
    let lastEditedDateFormatted = null;
    const editedAt = this.post?.edited_at || this.repost?.edited_at;

    if (post.edited_at) {
      try {
        lastEditedDate = new Date(post.created_at);
        lastEditedDateFormatted = Intl.DateTimeFormat(navigator.languages, {
          dateStyle: "short",
          timeStyle: "short",
        }).format(lastEditedDate);
      } catch {
        console.warn("Can't parse date (post): " + post.edited_at);
        // ignore
      }
    }
    return html`
      <social-interaction>
        <flex-container class="align-items-center social-info force-row">
          <flex-item>
            <button
              class="tiny beige love ${post.like?.type === 1 ? "active" : ""}"
              type="button"
              data-type="1"
              data-type-code="love"
              @click=${() => this.#likePost("love", 1)}
            >
              <svg-icon class="red" icon="love" size="14px"></svg-icon>
              ${this.stats?.love ?? 0}
            </button>
          </flex-item>

          <flex-item>
            <button
              class="tiny beige like ${post.like?.type === 0 ? "active" : ""}"
              type="button"
              data-type="0"
              data-type-code="like"
              @click=${() => this.#likePost("like", 0)}
            >
              <svg-icon class="blue" icon="like" size="14px"></svg-icon>
              ${this.stats?.like ?? 0}
            </button>
          </flex-item>

          <flex-item>
            <button
              class="tiny beige idea ${post.like?.type === 2 ? "active" : ""}"
              type="button"
              data-type="2"
              data-type-code="idea"
              @click=${() => this.#likePost("idea", 2)}
            >
              <svg-icon class="green" icon="idea" size="15px"></svg-icon>
              ${this.stats?.idea ?? 0}
            </button>
          </flex-item>

          <flex-item style="margin-left: auto; cursor: pointer;">
            <h5
              @click=${async () => {
                this.showComments = true;
                this.#loadComments();
              }}
            >
              ${this.stats?.comments ?? 0}
              Comment${this.stats?.comments === 1 ? "" : "s"}
            </h5>
          </flex-item>
        </flex-container>
        ${editedAt
          ? html`
              <div
                title="${lastEditedDateFormatted ?? "?"}"
                class="last-edited"
              >
                Last edited at
                ${lastEditedDate.format({
                  mode: "auto",
                  dateStyle: "long",
                  timeStyle: undefined,
                  rangeDays: 2,
                })}
              </div>
            `
          : nothing}
        ${this.#renderComments()} ${this.#renderAddComments()}
      </social-interaction>
    `;
  }

  renderTipOfTheDayEntry() {
    return html`
      <section class="card tip" data-timeline-id="${this.entry?.id}">
        <flex-container class="align-items-center">
          <flex-item>
            <badge-tag class="blue">Qogni Science</badge-tag>
          </flex-item>
          <flex-item>
            <nav id="account" data-dropdown class="align-right img-button">
              <button title="Menu" class="simple">
                <svg-icon icon="meatballs"></svg-icon>
              </button>
              <menu>
                ${!this.unsave
                  ? html`
                      <li>
                        <a
                          @click=${() =>
                            this.#onSave(this.option.id, "option_id")}
                        >
                          <svg-icon icon="save}" size="20px"></svg-icon>
                          Save
                        </a>
                      </li>
                    `
                  : nothing}
                ${this.unsave
                  ? html`
                      <li>
                        <a @click=${this.#onUnsave}>
                          <svg-icon icon="save}" size="20px"></svg-icon>
                          Unsave
                        </a>
                      </li>
                    `
                  : nothing}
                <li>
                  <a @click=${() => this.#onTipOfDayShare(this.option)}>
                    <svg-icon
                      icon="${navigator.canShare ? "share" : "copy"}"
                      size="20px"
                    ></svg-icon>
                    ${navigator.canShare ? "Share" : "Copy Link"}
                  </a>
                </li>
              </menu>
            </nav>
          </flex-item>
        </flex-container>

        <translatable-content content-name="tip" current-language="en">
          <p class="mb-none">${this.option.name}</p>
        </translatable-content>
      </section>
    `;
  }

  #renderComments() {
    if (!this.showComments) return nothing;

    return html`
      <comment-list>
        ${repeat(this.comments, this.renderComment)}
        ${this.commentsLoading
          ? html`
              <section class="card">
                <app-shimmer class="title"></app-shimmer>
                <app-shimmer class="mb"></app-shimmer>
              </section>

              <section class="card">
                <app-shimmer class="title"></app-shimmer>
                <app-shimmer class="mb"></app-shimmer>
              </section>
            `
          : nothing}
        ${this.commentsPagination && this.commentsPagination.next_cursor
          ? html`
              <flex-container>
                <flex-item class="grow-1">
                  <button
                    type="button"
                    class="tiny wide"
                    @click=${() =>
                      this.#loadComments(
                        this.commentToPost,
                        this.commentsPagination.next_cursor,
                        true
                      )}
                  >
                    Load more comments...
                  </button>
                </flex-item>
              </flex-container>
            `
          : nothing}
      </comment-list>
    `;
  }

  renderComment(comment) {
    return html`
      <section class="card comment">
        <comment-author>
          <flex-container>
            <flex-item>
              <figure>
                <img
                  src="${comment.user?.profile_img_url ??
                  "/assets/img/profile-picture.webp"}"
                  alt="User profile picture"
                  loading="lazy"
                />
              </figure>
            </flex-item>
            <flex-item>
              <strong
                >${comment.user?.firstname} ${comment.user?.lastname}</strong
              >
              commented
              <span
                class="time-ago"
                title="${Intl.DateTimeFormat(navigator.languages, {
                  dateStyle: "short",
                  timeStyle: "short",
                }).format(new Date(comment.created_at))}"
              >
                ${new Date(comment.created_at).format({
                  mode: "auto",
                  dateStyle: "long",
                  timeStyle: "short",
                  rangeDays: 2,
                })}
              </span>
            </flex-item>
          </flex-container>
        </comment-author>

        <comment-content>
          ${unsafeHTML(new Converter().makeHtml(comment.content))}
        </comment-content>
      </section>
    `;
  }

  #renderAddComments() {
    const isReposted = !!this.stats?.reposted && !!this.repost;

    return html`
      ${!this.commentToPost
        ? nothing
        : html`
            <comment-container>
              <rich-editor
                name="content"
                placeholder="Create your comment on the specific post..."
                value=""
                rows="2"
                ${ref(this.#commentRichEditorRef)}
              >
              </rich-editor>
            </comment-container>
          `}

      <flex-container class="social-controls align-items-stretch force-row">
        <flex-item class="grow align-self-stretch">
          ${this.commentToPost
            ? html`
                <button class="beige wide" @click=${this.#addComment}>
                  <svg-icon size="18px" icon="comment"></svg-icon>
                  <span> Post comment </span>
                </button>
              `
            : html`
                <button
                  class="beige wide"
                  @click=${() => (this.commentToPost = true)}
                >
                  <svg-icon size="18px" icon="comment"></svg-icon>
                  <span> Comment </span>
                </button>
              `}
        </flex-item>
        <flex-item>
          <button
            class="beige small"
            @click=${this.#sharePost.bind(this)}
            name="repost"
            ?disabled=${isReposted}
          >
            <svg-icon icon="repost" size="28px"></svg-icon>
            Repost
          </button>
        </flex-item>
      </flex-container>
    `;
  }
}

customElements.define("post-entry", PostEntry);

import {parseHTML} from "@qogni-technologies/design-system/src/shared/common";
import {PureApp} from "./pure.app";

/**
 * Default Page component (uses Light DOM)
 * @extends LitElement
 */
export class PWAPage extends PureAppPageMixin(PureApp.Page) {
}

export class WidgetEnabledPWAPage extends PureAppPageMixin(
  PureApp.WidgetEnabledPage
) {
}

export function PureAppPageMixin(superclass) {
  return class extends superclass {
    get isAuthenticated() {
      return false;
    }

    get isOnboarded() {
      return app.isOnboarded;
    }

    updated() {
      document.documentElement.classList.remove("is-authenticated");
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      app.topBar.reset();
    }
  };
}

/**
 * Mixin for checking if the user is authenticated.
 * @template T
 * @param {T} superclass
 * @returns {T}
 */
export function AuthenticatedMixin(superclass) {
  return class extends superclass {
    get isAuthenticated() {
      return true;
    }

    async connectedCallback() {
      if (!app.session.isAuthenticated) {
        const url = new URL(window.location.href);
        if (url.pathname === '/' && url.searchParams.has('invite')) {
          const inviteCode = url.searchParams.get('invite');
          const newPathname = '/enter';
          const newUrl = `${window.location.origin}${newPathname}?invite=${inviteCode}`;
          
          location.replace(newUrl);
          return;
        }
        return location.replace("/enter")
      };
      await super.connectedCallback();
    }

    updated() {
      if (app.session.isAuthenticated) document.documentElement.classList.add("is-authenticated");
    }
  };
}

/**
 * Mixin for showing page as full-screen (no sidebar/bottom bar).
 * @template T
 * @param {T} superclass
 * @returns {T}
 */
export function FullscreenMixin(superclass) {
  return class extends superclass {
    connectedCallback() {
      super.connectedCallback();
      app.classList.add("no-sidebar");
      app.classList.add("no-bottom-bar");
      app.classList.add("no-top-bar");
    }

    disconnectedCallback() {
      super.disconnectedCallback();
      app.classList.remove("no-sidebar");
      app.classList.remove("no-bottom-bar");
      app.classList.remove("no-top-bar");
    }
  };
}

/**
 * Mixin for checking if the user has completed required onboarding (name).
 * @template T
 * @param {T} superclass
 * @returns {T}
 */
export function OnboardedMixin(superclass) {
  return class extends superclass {
    async connectedCallback() {
      await super.connectedCallback();
      if (app.session.isOnboarded === false && app.session.user)
        return location.replace("/onboarding");

      // Also act if the profile is updated for the first time only.
      let init = false;
      app.session.on("profile-updated", () => {
        if (init) return;
        init = true;
        if (app.session.isOnboarded === false)
          return location.replace("/onboarding");
      });
    }
  };
}

/**
 * Mixin for checking roles of the user.
 * @template T
 * @param {array} roles single or list with roles to check for. Roles will be checked based on 'or' ruling.
 * @param {String} redirect
 * @param {T} superclass
 * @returns {T}
 */
export function RoleMixin(roles, superclass, redirect = "/") {
  if (!Array.isArray(roles)) roles = [roles];

  return class extends superclass {
    async connectedCallback() {
      await super.connectedCallback();
      const matchedRoles = roles.filter((role) => this.session.hasRole(role));
      if (matchedRoles.length === 0) return location.replace(redirect);
    }
  };
}

export function PullToRefreshMixin(superclass) {
  return class extends superclass {
    #touchstartY = 0;
    #pullToRefresh;

    async connectedCallback() {
      await super.connectedCallback();
      this.#initPullToRefresh();
    }

    async disconnectedCallback() {
      this.#destroyPullToRefresh();
      await super.disconnectedCallback();
    }

    #initPullToRefresh() {
      if (this.widget)
        return;

      const pullToRefreshSpinner =
        parseHTML(/*html*/ `<div class='pull-to-refresh'>
        <svg-icon icon="spinner" size="40px" color="" spinner></svg-icon>
      </div>`)[0];
      this.insertAdjacentElement("afterbegin", pullToRefreshSpinner);

      this.#pullToRefresh = this.renderRoot.querySelector(".pull-to-refresh");

      document.addEventListener(
        "touchstart",
        this.#touchStartHandler.bind(this),
        {passive: false}
      );
      document.addEventListener(
        "touchmove",
        this.#touchMoveHandler.bind(this),
        {passive: false}
      );
      document.addEventListener("touchend", this.#touchEndHandler.bind(this), {
        passive: false,
      });
    }

    #destroyPullToRefresh() {
      if (this.#pullToRefresh)
        this.#pullToRefresh.remove();
      document.removeEventListener(
        "touchstart",
        this.#touchStartHandler.bind(this),
        {passive: false}
      );
      document.removeEventListener(
        "touchmove",
        this.#touchMoveHandler.bind(this),
        {passive: false}
      );
      document.removeEventListener(
        "touchend",
        this.#touchEndHandler.bind(this),
        {passive: false}
      );
    }

    #touchStartHandler(e) {
      this.#touchstartY = e.touches[0].clientY;
    }

    #touchMoveHandler(e) {
      const touchY = e.touches[0].clientY;
      const touchDiff = touchY - this.#touchstartY;

      if (
        touchDiff > 250 &&
        window.scrollY === 0 &&
        !this.#pullToRefresh.classList.contains("visible")
      ) {
        this.#pullToRefresh.classList.add("visible");
        e.preventDefault();
      }
    }

    #touchEndHandler() {
      if (this.#pullToRefresh.classList.contains("visible")) {
        this.dispatchEvent(new CustomEvent("refresh"));
        this.#pullToRefresh.classList.remove("visible");
      }
    }
  };
}

import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { ArticleDomain } from "../../domain/article-domain";
import { Task } from "@qogni-technologies/design-system/src/shared/task";

export class PageSingleArticle extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #domain;

  static get properties() {
    return {
      articleId: { type: String },
      article: { type: Object },
    };
  }

  constructor() {
    super();
    this.#domain = new ArticleDomain();
  }

  connectedCallback() {
    super.connectedCallback();
    this.articleId = app.activeRoute.patternResult.pathname.groups.id;
    if (this.articleId) {
      this.#getPost();
    }
  }

  async #getPost() {
    const task = async () => {
      this.article = await this.#domain.getArticle(this.articleId);
    };

    await Task.run(task, {
      ghost: this,
      description: "Loading article...",
    });
  }

  render() {
    if (!this.article)
      return html`
        <app-shimmer class="title"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>

        <app-shimmer class="title"></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer></app-shimmer>
        <app-shimmer class="mb"></app-shimmer>
      `;

    return html`<article-entry
      .articleId=${this.articleId}
      .article=${this.article}
    ></article-entry>`;
  }
}

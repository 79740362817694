import { askConfirm } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { enhanceInputs } from "@qogni-technologies/design-system/src/enhancements/form-input.js";
import { Task } from "@qogni-technologies/design-system/src/shared/task";
import { LitElement, html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { AccountDomain } from "../../domain/account-domain";

customElements.define(
  "manage-emails",
  class ManageEmails extends LitElement {
    createRenderRoot() {
      return this;
    }

    #domain;
    #emailInputRef = createRef();

    static get properties() {
      return {
        showEmailsList: { type: Boolean },
        _emails: { type: Array },
        _addNewEnabled: { type: Boolean },
      };
    }

    constructor() {
      super();
      this.#domain = new AccountDomain();

      this._emails = [];
    }

    async connectedCallback() {
      await super.connectedCallback();
      this.#getEmails();
    }

    firstUpdated() {
      enhanceInputs(this);
    }

    render() {
      return html`
        <flex-container breakpoint="tiny" id="tag-email">
          <flex-item class="col-6 grow-2">
            <input
              name="email"
              data-label="Email"
              style="width:100%;"
              disabled
              value=${app.session?.user?.email}
            />
          </flex-item>
          <flex-item class="col-6 grow-2">
            <button
              type="button"
              class="button wide"
              @click=${() => {
                this.showEmailsList = !this.showEmailsList;
              }}
            >
              Manage Email addresses
            </button>
          </flex-item>
        </flex-container>

        ${this.#renderEmailsList()}
      `;
    }

    #renderEmailsList() {
      if (!this.showEmailsList) return nothing;

      const sortedEmails = [...this._emails].sort((a, b) => {
        // First, compare based on 'primary' key
        if (a.primary !== b.primary) {
          return b.primary - a.primary;
        }

        // If 'primary' is the same, compare based on 'verified_at'
        if (a.verified_at && b.verified_at) {
          // Both have verified_at, sort by date
          return new Date(a.verified_at) - new Date(b.verified_at);
        } else if (a.verified_at) {
          // Only a has verified_at, it comes first
          return -1;
        } else if (b.verified_at) {
          // Only b has verified_at, it comes first
          return 1;
        }
      });

      return html`
        <section class="card emails-list">
          ${repeat(sortedEmails, (item) => {
            const { verified_at, primary, work } = item;
            return html`
              <section class="card emails-list-item">
                <div class="email-label">${item.email}</div>
                <div class="trailing-part">
                  <div class="badge-container">
                    ${primary
                      ? html`<badge-tag class="green">Primary</badge-tag>`
                      : nothing}
                    ${verified_at
                      ? html`
                          <badge-tag class="blue">
                            <svg-icon icon="check"></svg-icon>
                            Verified
                          </badge-tag>
                        `
                      : html`
                          <badge-tag class="red">
                            <svg-icon icon="close"></svg-icon>
                            Not verified
                          </badge-tag>
                        `}
                    ${work
                      ? html`<badge-tag class="yello">Work</badge-tag>`
                      : nothing}
                  </div>
                  <div class="email-actions">
                    ${!primary && verified_at
                      ? html`
                          <button
                            type="button"
                            class="small"
                            @click=${() => this.#onMakePrimary(item)}
                          >
                            Make primary
                          </button>
                        `
                      : nothing}
                    ${!verified_at
                      ? html`
                          <button
                            type="button"
                            class="small"
                            @click=${() => this.#onVerify(item, true)}
                          >
                            Verify
                          </button>
                        `
                      : nothing}
                    ${!primary
                      ? html`
                          <button
                            type="button"
                            class="small round red delete"
                            @click=${() => this.#onDelete(item)}
                          >
                            <svg-icon icon="trash" size="16px"></svg-icon>
                          </button>
                        `
                      : nothing}
                  </div>
                </div>
              </section>
            `;
          })}
          ${this._addNewEnabled
            ? html`<form>
                <label>
                  <span data-label="">New email</span>
                  <input
                    ${ref(this.#emailInputRef)}
                    id="newEmail"
                    name="email"
                    type="email"
                    required
                    placeholder="Enter your new email address..."
                    @change=${this.#onEmailChange}
                    @input=${this.#onEmailChange}
                    @keydown=${this.#onEmailKeydown}
                  />
                </label>
                <button
                  type="button"
                  class="button wide small"
                  @click=${this.#onAddNewEmail}
                >
                  Add
                </button>
              </form>`
            : html`<button
                type="button"
                class="small wide"
                @click=${() => (this._addNewEnabled = true)}
              >
                Add New Email Address
              </button>`}
        </section>
      `;
    }

    async #getEmails() {
      const task = async () => {
        this._emails = await this.#domain.getEmails();
      };

      await Task.run(task, { ghost: this });
    }

    async #onMakePrimary(item) {
      const task = async () => {
        await this.#domain.updateEmail(item.id, { primary: true });
        await this.#getEmails();
        app.session.user["email"] = item.email;
        app.addToastMessage(`Primary email updated to ${item.email}!`);
        this.requestUpdate();
      };

      await Task.run(task, { ghost: this });
    }

    async #onVerify(item, refreshList = false) {
      let jwt;
      const verificationCodeTask = async () => {
        jwt = await this.#domain.emailVerificationRequestCode(item.id);
      };

      await Task.run(verificationCodeTask, { ghost: this });

      const dialog = document.createElement("modal-dialog");
      dialog.setAttribute("open", "");
      dialog.setAttribute("animation", "slideUp");

      // Append the custom element to the body
      document.body.appendChild(dialog);

      const confirmEmail = (element) => {
        return new Promise((resolve) => {
          element.onclose = (e) => resolve(e.detail.reason);

          const submitBtn = element.querySelector("#confirm");
          const pinInput = element.querySelector('input[name="pin"]');

          pinInput.addEventListener('keydown', (e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              const isValid = pinInput.reportValidity();
              if (!isValid) return;
              submitBtn?.click();
            }
          });

          submitBtn.onclick = async () => {
            const verificationCompleteTask = async () => {
              const pin = pinInput.value;
              if (!pin) return resolve("dismiss");
              const confirmed = await this.#domain.emailVerificationComplete(
                item.id,
                jwt,
                pin
              );
              return resolve(confirmed ? "confirm" : "dismiss");
            };

            await Task.run(verificationCompleteTask, { ghost: this });
          };
        });
      };

      // Define your external content
      const externalContent = `
        <span slot="title">Confirm Email!</span>
        <p>We sent a verification email to your new email address. <br>Please copy and paste the code here.</p>
        <form class="material">
          <label>
            <span data-label="">Verification code</span>
            <input name="pin" inputmode="numeric" pattern="[0-9]" autocomplete="one-time-code" type="number" placeholder="Paste change email code" required>
          </label>
        </form>
        <button slot="action" class="mb-tiny tiny outline beige" dismiss>Cancel</button>
        <button slot="action" class="mb-tiny tiny blue" id="confirm">Confirm</button>
      `;

      // Insert the external content into the custom element
      dialog.insertAdjacentHTML("beforeend", externalContent);

      const isConfirmed = (await confirmEmail(dialog)) === "confirm";
      dialog.remove();

      if (isConfirmed && refreshList) await this.#getEmails();

      return isConfirmed;
    }

    async #onDelete(item) {
      const isConfirmed = await askConfirm({
        title: "Confirm delete",
        message: `Are you sure you want to delete <b>${item.email}</b>?`,
      });

      const task = async () => {
        await this.#domain.deleteEmail(item.id);
        this.#getEmails();
      };

      if (isConfirmed) await Task.run(task, { ghost: this });
    }

    async #onAddNewEmail() {
      const value = this.#emailInputRef.value.value;

      const task = async () => {
        const res = await this.#domain.addNewEmail(value);

        if (res) {
          await this.#onVerify(res);
          await this.#getEmails();
        }
        this._addNewEnabled = false;
      };

      await Task.run(task, { ghost: this });
    }

    #onEmailChange(e) {
      e.preventDefault();
      e.stopPropagation();
    }

    #onEmailKeydown(e) {
      if (e.keyCode === 13) {
        e.preventDefault();
        e.stopPropagation();

        const target = e.target;
        const isValid = target.reportValidity();

        if (!isValid) return;
        this.#onAddNewEmail();
      }
    }
  }
);

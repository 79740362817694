import { ModalDialog, showAlert } from "@qogni-technologies/design-system/src/components/base/modal-dialog.js";
import { html, css } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { PostDomain } from "../../domain/post-domain";
import { Task } from "@qogni-technologies/design-system/src/shared/task";

export class EditPostDialog extends ModalDialog {
  #domain;
  #richEditorRef = createRef();

  static get styles() {
    return [
      ...ModalDialog.styles,
      css`
        dialog {
          min-width: 380px;
        }
      `,
    ];
  }

  static get properties() {
    return {
      post: { type: Object },
    };
  }

  constructor() {
    super();
    this.animation = "slideUp";
    this.#domain = new PostDomain();
  }

  get dialogHeader() {
    return html` Edit Post `;
  }

  get dialogContent() {
    const { content } = this.post;

    return html`
      <rich-editor ${ref(this.#richEditorRef)} .value=${content} fixed-toolbar></rich-editor>
    `;
  }

  get dialogFooter() {
    return html`
        <button class="small outline red" @click=${this.closeClick}>
          Cancel
        </button>
        <button class="small blue" @click=${this.#onSave}>Save</button>
    `;
  }

  async #onSave() {
    const task = async () => {
      const {id} = this.post;
      const content = this.#richEditorRef.value.getFormValue();

      if (content.length < 20) {
        return await showAlert({ title: "Invalid data", message: "The content must be at least 20 characters."});
      }
      await this.#domain.updatePost(id, content);
      this.dispatchEvent(new CustomEvent('success'));
      this.closeClick();
    }

    await Task.run(task, {
      ghost: this
    })
  }
}

customElements.define("edit-post-dialog", EditPostDialog);

import { html } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../../../shared/pwa-page";
import { CircleDomain } from "../../../../domain/circle-domain";
import { Task } from "@qogni-technologies/design-system/src/shared/task";

export class PageNewCircle extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #circleDomain;

  constructor () {
    super();
    this.#circleDomain = new CircleDomain();
  }

  render() {
    return html`
      <div class="center">
        <h1>New circle</h1>
      </div>
      <section class="card">
        <x-form @action=${this.#createNewCircle}>
          <form>
            <input name="circle-name" data-label="Name" required placeholder="HR Colleagues" />
            <label>
              Description
              <textarea
                name="circle-description"
                rows="4"
                placeholder="Write a description here..."
                value=""
              ></textarea>
            </label>
            <button type="submit" class="small wide" name="publish">Save circle</button>
          </form>
        </x-form>
      </section>
    `;
  }

  async #createNewCircle(e) {
    if (e.detail?.name !== '--submit') return;

    let response;

    const task = async () => {
      const name = e.detail.value['circle-name'];
      const description = e.detail.value['circle-description'] || "";

      response = await this.#circleDomain.createCircle(name, description);
    }

    await Task.run(task, {
      ghost: this,
      description: "Updating profile",
    });

    if (!response) return false
    window.location.replace(`/network/circles/${response.id}`);
  }
}

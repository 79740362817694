import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../shared/pwa-page";
import { AccountDomain, Languages } from "../domain/account-domain";
import { Task } from "@qogni-technologies/design-system/src/shared/task";
import { throttle } from "@qogni-technologies/design-system/src/shared/common";

export class PageSettings extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #deleteVisible = false;
  #deleteJwt = null;

  #domain;
  #deleteReason;
  #deleteReasonExtra;

  constructor() {
    super();
    this.#domain = new AccountDomain();
  }

  connectedCallback() {
    super.connectedCallback();
    app.session.on("profile-updated", () => {
      this.requestUpdate();
    });
  }

  render() {
    return html`
      <section class="hero">
        <h1 class="hide-full-width">Settings</h1>
      </section>

      <h3>Notification Settings</h3>
      <section class="card">
        <setting-radio>
          <input
            name="setting"
            type="radio"
            value="default"
            id="default"
            @change=${this.#updateNotificationState}
            ?checked=${app?.session?.user?.notifications_enabled}
          />
          <label for="default">
            <svg-icon icon="bell" size="32px"></svg-icon>
            <div>
              <h4>Ring or vibrate</h4>
            </div>
          </label>

          <input
            name="setting"
            type="radio"
            value="silent"
            id="silent"
            @change=${this.#updateNotificationState}
            ?checked=${app?.session?.user?.notifications_enabled === 0}
          />
          <label for="silent">
            <svg-icon icon="bell" size="32px"></svg-icon>
            <div>
              <h4>Silent</h4>
            </div>
          </label>
        </setting-radio>
      </section>

      <h3>Application Settings</h3>
      <section class="card account">
        <x-form
          live
          @statechange=${throttle(this.#updateForm.bind(this), 1000)}
          @action=${this.#updateForm}
        >
          <form action="" method="post" class="material">
            <label>
              <select name="language">
                ${Languages.map(
                  (lang) => html`
                    <option
                      value="${lang.value}"
                      ?selected=${app.session?.user?.language === lang.value}
                    >
                      ${lang.label}
                    </option>
                  `
                )}
              </select>
              <svg-icon icon="caret" size="10px"></svg-icon>
            </label>
            <section
              class="card yellow"
              ?hidden=${app.session?.user?.language === "en-US"}
            >
              <p>
                <svg-icon
                  icon="warning"
                  color="white"
                  style="display: inline-block;"
                  size="24px"
                ></svg-icon>
                <strong
                  >Op dit moment zijn alleen de Questionnaires in het
                  Nederlands.</strong
                >
              </p>
            </section>
          </form>
        </x-form>
      </section>

      <details class="bg-white red">
        <summary>
          Danger zone
          <svg-icon
            icon="warning"
            size="30px"
            style="--icon-fill-color: var(--color-accent)"
            class="right"
          ></svg-icon>
        </summary>
        <section class="card">
          <button
            name="delete"
            class="primary wide red"
            type="button"
            @click="${this.#toggleDeleteContainer}"
          >
            Delete my account
          </button>
        </section>

        ${this.renderDeletePart()}
      </details>
    `;
  }

  renderDeletePart() {
    if (!this.#deleteVisible) return nothing;
    return html`
      <section class="card delete-account">
        <x-form @action=${this.#deleteAction}>
          <form method="get" action="" class="material center">
            <fieldset>
              <legend>
                <h2>Delete your account</h2>
                <p>
                  We sent a verification email in order to confirm the deletion
                  of your account. Please copy and paste the code here.
                </p>
              </legend>
              <input
                name="pin"
                inputmode="numeric"
                pattern="[0-9]"
                autocomplete="one-time-code"
                data-label="Verification code"
                type="number"
                placeholder="Paste change email code"
              />

              <p>
                After confirming, you will receive an email once the deletion
                has been completed. Remember, after deleting your account we can
                not recover any of your data.
              </p>
              <button name="confirm" class="primary wide" type="submit">
                Confirm deleting my account.
              </button>
            </fieldset>
          </form>
        </x-form>
      </section>
    `;
  }

  /**
   * On-the-fly part of the form, change actions.
   * @param e
   * @returns {Promise<void>}
   */
  async #updateForm(e) {
    let updatedData = {};
    if (e.detail.name === "x-form-data") return; // Ignore initial event.
    updatedData[e.detail.name] = e.detail.value;

    const task = async () => {
      await this.#domain.updateProfile(updatedData);
      if (app.session.user) {
        app.session[e.detail.name] = e.detail.value;
      }
      await app.session.refreshUser(true);
    };

    return Task.run(task, {
      ghost: e.target,
      description: "Updating profile",
    });
  }

  async #updateNotificationState(e) {
    e.preventDefault();

    const task = async () => {
      const isEnabled = e.target.value !== "silent";

      if (isEnabled) {
        if (app.notification.blockedNotifications) {
          e.target.checked = false;
          e.target
            .closest("setting-radio")
            .querySelector('input[value="silent"]').checked = true;
          return app.addToastMessage(
            "You disabled Push Notifications in your Browser, please re-enable to receive notifications!"
          );
        }

        // Ask for permission right now at this spot.
        if ((await app.notification.askPermission()) === "denied") {
          e.target.checked = false;
          e.target
            .closest("setting-radio")
            .querySelector('input[value="silent"]').checked = true;
          return app.addToastMessage(
            "You denied Notifications in your browser, please re-enable to receive notifications!"
          );
        }
      }

      // Only now update the settings of the profile.
      await this.#domain.updateProfile({
        notifications_enabled: isEnabled,
      });
    };

    return Task.run(task, {
      ghost: e.target.closest("setting-radio"),
      description: "Setting notification status.",
    });
  }

  async #deleteAction(e) {
    if (e.detail?.name !== "--submit") return;
    const task = async () => {
      try {
        await this.#domain.deleteConfirm(this.#deleteJwt, e.detail.value.pin, this.#deleteReason, this.#deleteReasonExtra);
      } catch (err) {
        if (err.response && err.errorData && err.errorData.message) {
          app.addToastMessage(err.errorData.message, { type: "error" });
          return;
        }
        app.addToastMessage(err, { type: "error" });
        return;
      }

      // Logout user.
      await app.session.logout();
      window.location.href = "/";
    };

    Task.run(task, {
      ghost: e.target,
      description: "Delete user account",
    });
  }

  async #toggleDeleteContainer(e) {
    const task = async () => {
      const userConfirmed = await this.#askDeletionConfirm();
      if (!userConfirmed) return;

      this.#deleteJwt = await this.#domain.deleteRequest();
      this.#deleteVisible = !this.#deleteVisible;
      this.requestUpdate();
    };
    Task.run(task, {
      ghost: e.target.closest("section"),
      description: "Requesting deletion code.",
    });
  }

  async #askDeletionConfirm() {
    return new Promise((resolve) => {
      const dialog = document.createElement("modal-dialog");
      dialog.setAttribute("open", "");
      dialog.setAttribute("animation", "slideUp");
      document.body.appendChild(dialog);

      let index = 0;

      const askQuestion = async (index) => {
        if (index === 0) {
          const content = `
            <span slot="title">Are you sure?</span>
            <p>All your progress will be lost such as your personalised experience based on your results.</p>
            <button slot="action" name="confirm" class="mb-tiny blue" value="cancel">No</button>
            <button slot="action" name="confirm" class="mb-tiny outline red" value="yes">Yes</button>
          `

          dialog.innerHTML = content;
        }

        if (index === 1) {
          const content = `
            <span slot="title">Why are you leaving us 😭 ?</span>
            <div style="display: flex; flex-direction: column; gap: var(--gutter-tiny); margin-bottom: var(--gutter-small);">
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="It wasn't what I was expecting">
                <span class="data-label">It wasn't what I was expecting</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="I don't use the App (anymore)">
                <span class="data-label">I don't use the App (anymore)</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="The packages are too expensive">
                <span class="data-label">The packages are too expensive</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="The App is too complicated">
                <span class="data-label">The App is too complicated</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="Missing feature(s)">
                <span class="data-label">Missing feature(s)</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="I have privacy concerns about the content and my data.">
                <span class="data-label">I have privacy concerns about the content and my data.</span>
              </label>
              <label style="margin: 0;">
                <input class="variant2" type="radio" name="reason" value="Other">
                <span class="data-label">Other</span>
              </label>
            </div>
            <label>
              Feedback
              <textarea name="extra" rows="4"></textarea>
            </label>
            <button slot="action" name="confirm" class="mb-tiny blue" value="cancel">Cancel</button>
            <button slot="action" id="submit-btn" name="confirm" class="mb-tiny outline red" value="yes" disabled>Submit</button>
          `;

          dialog.innerHTML = content;
        }
      }

      dialog.addEventListener('click', (event) => {
        const target = event.target;

        if (index === 0 && target.matches('[name="confirm"]')) {
          setTimeout(() => {
            const value = target.value;
            if (value === "yes") {
              index++;
              askQuestion(index);
            } else {
              finishWizard(false);
            }
          }, 100);
        }

        if (index === 1 && target.matches('[name="reason"]')) {
          const submitBtn = dialog.querySelector('button[id="submit-btn"]');
          submitBtn.removeAttribute('disabled');

          this.#deleteReason = target.value;
        }

        if (index === 1 && target.matches('[name="confirm"]')) {
          setTimeout(() => {
            const value = target.value;
            if (value === "yes") {
              const textarea = dialog.querySelector('textarea');
              this.#deleteReasonExtra = textarea.value;
              finishWizard(true);
            } else {
              finishWizard(false);
            }
          }, 100);
        }
      });

      const finishWizard = (isConfirmed = true) => {
        dialog.remove();
        resolve(isConfirmed);
      };

      askQuestion(index);
    })
  }
}
